import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalModule, MsalService } from '@azure/msal-angular';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '@config/sso-auth-config';
import { LOCAL_STORAGE_CONSTANT } from '@constants/localstorage.constant';
import { IPartnerClientId } from '@db-models/partner-db.model';
import { environment } from '@environments/environment';
import { ConfigService } from '@feature-services/config.service';
import { GlobalErrorHandler } from '@interceptors/global-error-handler.interceptor';
import { NoopHttpInterceptor } from '@interceptors/noop-http.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from "@sentry/angular-ivy";
import { CalioDateAdapterService } from '@util-services/calio-date-adapter.service';
import { CryptoService } from '@util-services/crypto.service';
import { CalioMatPaginatorIntlService } from '@util-services/mat-paginator.service';
import { DateAdapter as CalendarDateAdapter, CalendarModule } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { provideNgxStripe } from 'ngx-stripe';
import { ToastrModule } from 'ngx-toastr';
import { take } from 'rxjs';

// locals import required for currency pipe and date pipe
import localeDe from '@angular/common/locales/de';
import localeDeCH from '@angular/common/locales/de-CH';
import localeEs from '@angular/common/locales/es';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeFrCH from '@angular/common/locales/fr-CH';
import localeIt from '@angular/common/locales/it';
import localeItCh from '@angular/common/locales/it-CH';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localeSv from '@angular/common/locales/sv';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { appRoutes } from '@app/app.routes';

export const createTranslateLoader = (http: HttpClient): TranslateHttpLoader => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const getLocalStorage = (): Storage | null => {
  return (typeof window !== 'undefined') ? window.localStorage : null;
}

export const mSALInstanceFactory = (cryptoService: CryptoService): IPublicClientApplication => {
  let ssoPartnerString = localStorage.getItem(LOCAL_STORAGE_CONSTANT.CALENSO_SELECTED_SSO_CLIENT_ID);
  if (ssoPartnerString) {
    const ssoPartnerClient = JSON.parse(cryptoService.decryptValue(ssoPartnerString)) as IPartnerClientId;
    msalConfig.auth.clientId = ssoPartnerClient.client_id;
  }
  return new PublicClientApplication(msalConfig);
}

export const mSALGuardConfigFactory = (): MsalGuardConfiguration => {
  return {
    interactionType: InteractionType.Redirect
  };
}

export const PICK_FORMATS = {
  parse: {
    dateInput: {
      month: 'short',
      year: 'numeric',
      day: 'numeric'
    }
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
};

// required for currency pipe and date pipe
registerLocaleData(localeDe, 'de');
registerLocaleData(localeDeCH, 'ch');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeFrCH, 'fr_CH');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeItCh, 'it_CH');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeFi, 'fi');
registerLocaleData(localePl, 'pl');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeSv, 'sv');

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      appRoutes,
      withComponentInputBinding(),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled'
      })
    ),
    provideAnimations(),
    provideHttpClient(
      withInterceptors([NoopHttpInterceptor])
    ),
    CurrencyPipe,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    importProvidersFrom(
      FormsModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        }
      }),
      BrowserAnimationsModule,
      ToastrModule.forRoot({
        preventDuplicates: true,
        newestOnTop: true,
        iconClasses: {
          error: 'toast-error',
          success: 'toast-success'
        }
      }),
      CalendarModule.forRoot({
        provide: CalendarDateAdapter,
        useFactory: adapterFactory,
      }),
      MsalModule,
    ),
    provideNgxStripe(environment.stripePublicKey),
    { provide: 'LOCALSTORAGE', useFactory: getLocalStorage },
    { provide: LOCALE_ID, useValue: 'de-ch' },
    { provide: DateAdapter, useClass: CalioDateAdapterService },
    { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } as RippleGlobalOptions },
    { provide: MSAL_INSTANCE, useFactory: mSALInstanceFactory, deps: [CryptoService] },
    { provide: MSAL_GUARD_CONFIG, useFactory: mSALGuardConfigFactory },
    { provide: MatPaginatorIntl, useClass: CalioMatPaginatorIntlService },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: APP_INITIALIZER, useFactory: () => () => { }, deps: [Sentry.TraceService], multi: true },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (configService: ConfigService) => {
        return () => {
          configService.getConfigs();
          return configService.configs$.pipe(take(1));
        };
      },
      deps: [ConfigService],
    },
  ]
}
