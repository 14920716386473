
import { Component, ComponentRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DynamicDirectiveDirective, DynamicDirectiveDirective as DynamicDirectiveDirective_1 } from '@calio-lib/calio-directives/dynamic-directive.directive';
import { TranslateModule } from '@ngx-translate/core';
import { CalioEventsService } from '@util-services/calio-events.service';
import { DynamicComponentLoaderService } from '@util-services/dynamic-component-loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-calio-dialog',
  templateUrl: './calio-dialog.component.html',
  styleUrls: ['./calio-dialog.component.scss'],
  standalone: true,
  imports: [DynamicDirectiveDirective_1, TranslateModule]
})
export class CalioDialogComponent implements OnInit, OnDestroy {

  @ViewChild(DynamicDirectiveDirective, { static: true }) dynamicDirective: DynamicDirectiveDirective;

  title: string;
  subtitle: string;
  showDialogCloseButton = true;
  subtitleTranslationJson: any = {};
  customHeaderClass: string;
  loadComponent: () => Promise<any>;
  compData: any;
  loadDynamicComponentSubscription: Subscription;
  closeCalioDialogEventSubscription: Subscription;
  closeCalioRefDialogEventSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<CalioDialogComponent>,
    private dynamicComponentLoaderService: DynamicComponentLoaderService,
    private calioEventsService: CalioEventsService,
    @Inject(MAT_DIALOG_DATA) public data: {
      loadComponent: () => Promise<any>,
      compData: any,
      dialogTitle: string,
      dialogSubTitle: string,
      showHeader: boolean,
      dialogSubtitleTranslationJson: any,
      customHeaderClass: string,
      showDialogCloseButton?: boolean
    },
  ) {
    this.title = data.dialogTitle;
    this.subtitle = data.dialogSubTitle;
    this.customHeaderClass = data.customHeaderClass;
    this.showDialogCloseButton = data.showDialogCloseButton === false ? false : true;
    this.loadComponent = data.loadComponent;
    this.compData = data.compData;
    if (data.dialogSubtitleTranslationJson) {
      this.subtitleTranslationJson = data.dialogSubtitleTranslationJson;
    }

    this.closeCalioDialogEventSubscription = this.calioEventsService.closeCalioDialogEvent.subscribe((result: any) => {
      this.dialogRef.close(result);
    });

    this.closeCalioRefDialogEventSubscription = this.calioEventsService.closeCalioRefDialogEvent.subscribe((
      result: { ref: any, result?: any }
    ) => {
      result.ref.close(result?.result);
    });

    this.calioEventsService.calioDialogTitleChangedEvent.subscribe((result: {
      title: string,
      subtitle: string
    }) => {
      this.title = result.title;
      this.subtitle = result.subtitle;
    });
  }

  ngOnInit(): void {
    const viewContainerRef = this.dynamicDirective.viewContainerRef;
    this.loadDynamicComponentSubscription = this.dynamicComponentLoaderService.loadComponentDynamically(
      viewContainerRef,
      this.loadComponent
    ).subscribe({
      next: (result: ComponentRef<any>) => {
        this.compData.dialogRef = this.dialogRef;
        result.instance.compData = this.compData;
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.loadDynamicComponentSubscription?.unsubscribe();
    this.closeCalioDialogEventSubscription?.unsubscribe();
    this.closeCalioRefDialogEventSubscription?.unsubscribe();
  }
}
