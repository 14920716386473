import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { THEME } from '@constants/app.constants';
import { LOCAL_STORAGE_CONSTANT } from '@constants/localstorage.constant';
import { PARTNER_SETTINGS } from '@constants/partner-settings.constants';
import { PartnerSettingModel } from '@db-models/partner-payment-account.model';
import { Theme } from '@db-models/worker-db.model';
import { PartnerSettingService } from '@feature-services/partner-setting.service';
import { WorkerService } from '@feature-services/worker.service';
import { HelperService } from '@util-services/helper.service';
import { LocalStorageService } from '@util-services/local-storage.service';
import { LoggerService } from '@util-services/logger.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private workerService = inject(WorkerService);
  private storageService = inject(LocalStorageService);
  private rendererFactory = inject(RendererFactory2);
  private router = inject(Router);
  private partnerSettingService = inject(PartnerSettingService);
  private helperService = inject(HelperService);

  public currentTheme: Theme = THEME.NEW as Theme;
  private renderer: Renderer2;
  public lastUiViewValue: 'mobile' | 'tablet' | 'laptop';
  public lastTextLabelValue: boolean;
  getCurrentTheme = new Subject<any>();

  constructor() {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.loadTheme();
    this.applyBackgroundColor();
    this.updateThemeVairableAfterThemeChange();
    this.getCurrentTheme.subscribe(() => this.updateThemeVairableAfterThemeChange())
  }

  getThemeKey(): string {
    const loggedInWorker = this.workerService.getLoggedInWorkerFromLocalStorage();
    return loggedInWorker ? `${LOCAL_STORAGE_CONSTANT.CURRENT_THEME}_${loggedInWorker.uuid}` : '';
  }

  public loadTheme(): void {
    const themeKey = this.getThemeKey();
    const storedTheme = this.storageService.get(themeKey);
    if (storedTheme) {
      this.currentTheme = Number(storedTheme) as Theme;
    } else {
      this.currentTheme = THEME.OLD;
    }
    this.getCurrentTheme.next(this.currentTheme);
  }

  public saveTheme(): void {
    this.applyBackgroundColor();
    const themeKey = this.getThemeKey();
    this.storageService.set(themeKey, String(this.currentTheme));
    this.getCurrentTheme.next(this.currentTheme);
  }

  private applyBackgroundColor(): void {
    const newThemeClass = 'new-theme';
    const oldThemeClass = 'old-theme';

    if (this.currentTheme === THEME.NEW) {
      this.renderer.addClass(document.body, newThemeClass);
      this.renderer.removeClass(document.body, oldThemeClass);
    } else {
      this.renderer.addClass(document.body, oldThemeClass);
      this.renderer.removeClass(document.body, newThemeClass);
    }
  }

  toggleTheme(themeValue: Theme): void {
    const partnerSetting = {
      setting_id: PARTNER_SETTINGS.USE_NEW_DASHBOARD_DESIGN,
      value: themeValue
    } as PartnerSettingModel;
    this.partnerSettingService.updatePartnerSettings(partnerSetting, false).subscribe({
      next: () => {
        this.currentTheme = themeValue;
        this.saveTheme();
        this.helperService.needToNavigateOnDashboard(this.router.url) && this.router.navigate(['/']);
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  private updateThemeVairableAfterThemeChange(): void {
    if (this.currentTheme === THEME.OLD) {
      document.documentElement.style.setProperty('--calenso-font-color', `#3A4A66`); // --calenso-primary-color: #3A4A66;
    } else if (this.currentTheme === THEME.NEW) {
      document.documentElement.style.setProperty('--calenso-font-color', `#000000`); // --calenso-black: #000000;
    } else {
      document.documentElement.style.setProperty('--calenso-font-color', `#000000`); // --calenso-black: #000000;
    }
  }
}
